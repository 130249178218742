exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bounces-tsx": () => import("./../../../src/pages/bounces.tsx" /* webpackChunkName: "component---src-pages-bounces-tsx" */),
  "component---src-pages-coupons-[id]-tsx": () => import("./../../../src/pages/coupons/[id].tsx" /* webpackChunkName: "component---src-pages-coupons-[id]-tsx" */),
  "component---src-pages-coupons-index-tsx": () => import("./../../../src/pages/coupons/index.tsx" /* webpackChunkName: "component---src-pages-coupons-index-tsx" */),
  "component---src-pages-coupons-new-tsx": () => import("./../../../src/pages/coupons/new.tsx" /* webpackChunkName: "component---src-pages-coupons-new-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-facebook-review-tsx": () => import("./../../../src/pages/facebook-review.tsx" /* webpackChunkName: "component---src-pages-facebook-review-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-promos-index-tsx": () => import("./../../../src/pages/promos/index.tsx" /* webpackChunkName: "component---src-pages-promos-index-tsx" */),
  "component---src-pages-promos-new-tsx": () => import("./../../../src/pages/promos/new.tsx" /* webpackChunkName: "component---src-pages-promos-new-tsx" */)
}

